import {
  Account,
  Connection,
  PublicKey,
  Keypair,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

import {cct_alert} from '@/libs/CCT_alert';

import BN from "bn.js";
import bs58 from "bs58";

import * as anchor from '@project-serum/anchor';
import Wallet from '@project-serum/sol-wallet-adapter';

import { establishConnection, getTokenAccountForMintAddress, fetchAccount, fetchAccountOffer, getTokenAccountForTokenAndOwner} from "@/libs/solanaConnection";

import { TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";

import _idl from "@/IDL_shop.json";

const idl = _idl;

// Address of the deployed program.
const program_id = new PublicKey('8eVvc2RWekGAj7ja7yUg5uTu9qKTNm54PDHDmYdb1pXa');

const VAULT_ACCOUNT_PREFIX_TOKEN = "aco_vault_token2LpkUGCdz2ugfofi";
const VAULT_AUTHORITY_PREFIX_TOKEN = "au_vault_token2LpkUGCdz2ugfofi";

// Adress of the token
// const aqua_token_address = 'FASohke1j5eRAwQBMqTs3W9d3N54xFqNAg3nZRmGKQdk';
const aquapipe_token_address = 'FjBddvSNjJZNzzZA1Lqq8QNCVBcmREyFNTriY6ZmC8ip';
// const token_account = '4mp9iLwjxGqsSpiaP5mjb39JrSe9uJRBSjLp8k6qHtuK';

const aqua_token_address = 'Cqt1MDAvMAoLd9KCikCpGiBGvkEv6FLoKz2ndyqUFDDG';

let connection: Connection;

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export async function check_for_transaction_confirmation(signature, attempts) {
	
	attempts++;
	
	if(attempts == 12) {
		
		cct_alert("Transaction failed to confirm, we don't know if it worked, check your wallet and try again.");
		return;
	}
	
	connection = await establishConnection();
	
	await connection.getTransaction(signature).then(async function(result) {

		if(result === null) {

			return new Promise(resolve => {
				setTimeout(async function () {
					resolve(check_for_transaction_confirmation(signature, attempts));
				}, 5000);
			});
			// return;
		}
		else {
			
			cct_alert("Thank you for your purchase !");
		}
		
		
	});
	
}

/*
export async function prepare_buy_ticket_program(wallet_address, wallet_provider, how_many_tickets) {
		
	var tokenAccount = await getTokenAccountForTokenAndOwner(token_address, wallet_address);
			
	await buy_ticket_program(wallet_provider, wallet_address, how_many_tickets, raffle_id, tokenAccount, ticket_price);
	
}
*/

async function prepareAta(nftMint, wallet_address) {
	
	// console.log("prepareAta, nftMint", nftMint);
	
	nftMint = new PublicKey(nftMint);
	wallet_address = new PublicKey(wallet_address);
	
	// we create the tokenAccount
	var myToken = new Token(
		connection,
		nftMint,
		TOKEN_PROGRAM_ID,
		wallet_address
	);
	
	
	const associatedDestinationTokenAddr = await Token.getAssociatedTokenAddress(
		myToken.associatedProgramId,
		myToken.programId,
		nftMint,
		wallet_address,  // to
		true
	);
	
	// check if it exists
	var receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr);
	
	var instruction = null;
	
	if (receiverAccount === null) {

		instruction = Token.createAssociatedTokenAccountInstruction(
			myToken.associatedProgramId,
			myToken.programId,
			nftMint,
			associatedDestinationTokenAddr,
			wallet_address, // to
			wallet_address
		)
	}
	
	return [instruction, associatedDestinationTokenAddr];
}

export async function buy_ticket_program(wallet_address, wallet_provider, how_many_tickets) {
	
	var program = null;
	
	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	program = new anchor.Program(idl, program_id, provider);
	
	// we need 4 tokens account
	
	// ATA for Aqua for the user
	var aqua_token_account_owner = await getTokenAccountForTokenAndOwner(aqua_token_address, wallet_address);
	
	// ATA for Aqua for the program
	// var aqua_token_account_program = new PublicKey('8x37i5A6GTBp3Bz4bQjM68uMucQQbhUn5x4CQ6bSdneU');
	var aqua_token_account_program = new PublicKey('EmFgcHW1NSeZjF7eLF5aQzFqrHdmKphBd1hF7Z9PZtx5');
	
	// ATA for Aqua Pipe for the user
	var [instruction_aqua_pipe_owner, aquapipe_token_account_owner] = await prepareAta(aquapipe_token_address, wallet_address);
	
	// ATA for Aqua Pipe for the program
	var aquapipe_token_account_program = new PublicKey('CtdP9pFUnzPgMsz4qVG7M3QJFRubn5VFxgA8YHxXdxM3');
	
	// and we need the vault authority to sign for the aquapipe transfer
	const [_vault_authority_pda, _vault_authority_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_AUTHORITY_PREFIX_TOKEN)],
		program_id
	);

	var vault_authority_pda = _vault_authority_pda;
	
	var instructions = [];
	
	// console.log('aquaAccountOwner', new PublicKey(aqua_token_account_owner).toString());
	// console.log('aquapipeAccountOwner', new PublicKey(aquapipe_token_account_owner).toString());
	// console.log('aquaAccountCct', new PublicKey(aqua_token_account_program).toString());
	// console.log('aquapipeAccountCct', new PublicKey(aquapipe_token_account_program).toString());
	
	var transaction;
	
	if(instruction_aqua_pipe_owner !== null) {
		
		transaction = await program.transaction.initialize(
			new anchor.BN(how_many_tickets),
			{
				accounts: {
					owner: wallet_address,
					
					aquaAccountOwner: new PublicKey(aqua_token_account_owner),
					aquapipeAccountOwner: new PublicKey(aquapipe_token_account_owner),
					aquaAccountCct: new PublicKey(aqua_token_account_program),
					aquapipeAccountCct: new PublicKey(aquapipe_token_account_program),
					vaultAuthority: vault_authority_pda,
					
					systemProgram: anchor.web3.SystemProgram.programId,
					rent: anchor.web3.SYSVAR_RENT_PUBKEY,
					tokenProgram: TOKEN_PROGRAM_ID,
				},
				instructions: [instruction_aqua_pipe_owner],
				// signers: [],
			}
		);
	}
	else {
		
		transaction = await program.transaction.initialize(
			new anchor.BN(how_many_tickets),
			{
				accounts: {
					owner: wallet_address,
					
					aquaAccountOwner: new PublicKey(aqua_token_account_owner),
					aquapipeAccountOwner: new PublicKey(aquapipe_token_account_owner),
					aquaAccountCct: new PublicKey(aqua_token_account_program),
					aquapipeAccountCct: new PublicKey(aquapipe_token_account_program),
					vaultAuthority: vault_authority_pda,
					
					systemProgram: anchor.web3.SystemProgram.programId,
					rent: anchor.web3.SYSVAR_RENT_PUBKEY,
					tokenProgram: TOKEN_PROGRAM_ID,
				},
				// instructions: [],
				// signers: [],
			}
		);
	}
	
	transaction.feePayer = new PublicKey(wallet_address);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	console.log('transaction', transaction);

	var signature = null;
	
	try {
		
		var signedTransaction = await wallet_provider.signTransaction(transaction);
		
		signature = await connection.sendRawTransaction(signedTransaction.serialize());
	}
	catch(error) {
		
		return;
	}
	
	console.log('signature', signature);
	
	// await check_for_transaction_confirmation(signature, 0);
		
	return signature;
}
