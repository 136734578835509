<template>
  <div>
	<span style="display: block;
    color: white;
    text-align: center;
    margin-bottom: 50px; font-size: 1.2vw; font-weight: bold;">A shamanic pipe used to channel Sea-MT</span>
    <div class="raffle-page-background">
	
	
      <div class="raffle-page-card">

        <span class="raffle-page-title">Aqua Pipe</span>
        <div class="raffle-page-line"></div>
        

        

        <div class="raffle-page-info">

          <div class="raffle-page-nft">
            <img src="https://img.coraltribe.io/aquapipe.jpg" />
          </div>

			<div class="raffle-page-details">
            <div class="raffle-page-details-text">
              
              <p>
                <span>
                  Price:
                </span>
                <span>
                  350 $AQUA /  Aqua Pipe
                </span>
              </p>
              <p class="raffle-page-tickets">
                <span class="raffle-page-gradient-font gradient-font" @click="withdraw_one_ticket()">
                  &#x2212;
                </span>
                <span>
                  {{ how_many_tickets }}
                </span>
                <span class="raffle-page-gradient-font gradient-font" @click="add_one_ticket()">
                  &#x2b;
                </span>
              </p>
            </div>

            <div class="raffle-page-button button">
              <div class="button-left-border"></div>
              <div class="button-right-border"></div>
              <div class="button-content" @click="buy_tickets()">
                <span>Buy</span>
                <span>{{ how_many_tickets }}</span>
                <span>Aqua Pipe</span>
              </div>
            </div>

          </div>


        </div>

      </div>
    </div>
    
  </div>
</template>

<script>

import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {buy_ticket_program} from '@/libs/rustProgramShop';
import {cct_alert} from '@/libs/CCT_alert';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

export default {
  name: 'AquaPipe',
  data : function (){

    return {

		how_many_tickets: 1,
    }

  },
  methods: {
  
	
	
	withdraw_one_ticket: function() {
		
		this.how_many_tickets--;
		
		if(this.how_many_tickets <= 0)
			this.how_many_tickets = 1;
	},
	
	add_one_ticket: function() {
	
	
		this.how_many_tickets++;
	},
	
	buy_tickets: async function() {
	
		if(this.$root.wallet_address === null) {
			
			// alert('Please connect your wallet first');
			this.$root.$emit('display_connection_modal');
			return;
		}
		
		this.$root.loading_gif = true;
		
		var signature = await buy_ticket_program(getWalletAddress(), getSolanaObject(), this.how_many_tickets);
		
		
		this.$root.loading_gif = false;
		

	},
	
	
	

  
	
  },
  mounted: function(){
	
		
	},
}
</script>

<style scoped>

</style>